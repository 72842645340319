/* eslint-disable max-lines */
import showdown from 'showdown'
import sanitizeHtml from 'sanitize-html'
import {
  get, kebabCase, sample, uniq,
} from 'lodash'
import { IMAGE_SIZES, PHOTOGRAPHERS } from './constants'
import { CANONICAL_URL } from './seo'

import {
  AllTiersT,
  GatsbyAirtableImages,
  ImageFit,
  ImageSizes,
  PeopleT,
  ProjectsT,
  SocialLink,
  SocialType,
  TransformedPerson,
  VENUE_NAMES,
} from './types'
import { contributionSort, exhibitionSort, linkSort } from './sorting'

// Assets
import hoerBadge from '../static/assets/hoer-badge.svg'
import convergenceBadge from '../static/assets/convergence-badge.svg'
import truBadge from '../static/assets/tru-badge.svg'
import omBadge from '../static/assets/om-badge.svg'
import avatar1 from '../static/assets/avatars/avatar-01.svg'
import avatar2 from '../static/assets/avatars/avatar-02.svg'
import avatar3 from '../static/assets/avatars/avatar-03.svg'
import avatar4 from '../static/assets/avatars/avatar-04.svg'
import avatar5 from '../static/assets/avatars/avatar-05.svg'
import avatar6 from '../static/assets/avatars/avatar-06.svg'
import placeholder from '../images/placeholder.png'
import fallbackShareImage from '../images/fallback-share-image.png'

export const arrayToReadableList = (arr: any[]): string => {
  const cleaned = arr.filter((item) => !!item)
  const final = cleaned.pop()
  return cleaned.length ? `${cleaned.join(', ')} & ${final}` : final
}
// Todo: a project can have multiple exhibitions and multiple anchors i.e VOCs telephone system
export const createProjectSlug = (project: ProjectsT): string => {
  if (!project) throw new Error('Missing Project!')
  // Todo: Make this better
  const projectData = get(project, 'data.Project[0].data', null)
  const exhibitionSlug = kebabCase(
    projectData?.Exhibition__from_Master_IP_?.split(',')[0],
  )
  const anchorSlug = projectData?.Anchor_Space_link?.[0]?.data?.Anchor_Space_Slug
  const projectSlug = projectData?.Project_Title_Slug
  if (!exhibitionSlug || !anchorSlug || !projectSlug) {
    return `Slugs are missing exhibitionSlug: ${exhibitionSlug} anchorSlug: ${anchorSlug} projectSlug: ${projectSlug}`
  }
  return `/${exhibitionSlug}/${anchorSlug}/${projectSlug}`
}

export const createAnchorSlug = (anchor: any): string => {
  if (!anchor) throw new Error('Missing Anchor Data!')
  const exhibitionSlug = anchor.data.Anchor_Space[0].data.Exhibition_link[0].data.Exhibition_Slug
  const anchorSlug = anchor.data.Anchor_Space[0].data.Anchor_Space_Slug
  if (!exhibitionSlug || !anchorSlug) {
    return `Slugs are missing exhibitionSlug: ${exhibitionSlug} anchorSlug: ${anchorSlug}`
  }
  return `/${exhibitionSlug}/${anchorSlug}`
}

export const createExhibitionContentSlug = (contentData: any): string => {
  if (!contentData) throw new Error('Missing Exhibition Content Data!')
  const exhibitionSlug = kebabCase(contentData?.Exhibition__from_Master_IP_)
  const homeAnchorSlug = contentData?.Anchor_Space_link?.[0].data.Anchor_Space_Slug
  const contentTitleSlug = contentData?.Exhibition_Content_Title_Slug
  return `/${exhibitionSlug}/${homeAnchorSlug}/${contentTitleSlug}`
}

export const createExhibitionSlug = (name: string): string => `/${kebabCase(name)}`

export const removeFilterOption = (
  prev: string[],
  option: string,
): string[] => {
  const newArr = prev.filter((el) => el !== option)
  return [...newArr]
}

export const getExhibitionLogo = (exhibitionTitle: string): string => {
  switch (true) {
  case exhibitionTitle === VENUE_NAMES.SAF:
    return hoerBadge
  case exhibitionTitle === VENUE_NAMES.LVS:
    return omBadge
  case exhibitionTitle === VENUE_NAMES.DEN:
    return convergenceBadge
  case exhibitionTitle === VENUE_NAMES.GVH:
    return truBadge
  default:
    return hoerBadge
  }
}

export const getRandomAvatar = (): string => {
  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6]
  return sample(avatars)
}

export const handleBrowseAway = (link: string): void => {
  // eslint-disable-next-line no-alert
  alert(`you are leaving ${CANONICAL_URL} and being redirected to ${link}`)
}

// helper fn for getOptimizedImage
export const getImageDimensions = (
  imageSize: ImageSizes,
): { height: number; width: number } => {
  let width = 100
  let height = 100
  const size = IMAGE_SIZES[imageSize]
  if (size) ({ width, height } = size)
  return {
    height,
    width,
  }
}

export const getOptimizedImage = ({
  domain = 'creditassets.meowwolf.com',
  imageSize,
  src,
  fit = 'crop',
  fallBack = 'placeholder',
}: {
  domain?: string
  imageSize: ImageSizes
  src: string | undefined
  fit?: ImageFit
  fallBack?: 'placeholder' | 'shareImage'
}): string => {
  // thumbnail dimensions w x h
  // large image dimensions w x h
  // use same dimension for project page images as exhibition image
  // image never bigger than the container its within
  // large: 870 x 580
  // thumbnail: 224 x 149
  // profile: 400 x 400
  // shareImage: 1200 x 630
  const { height, width } = getImageDimensions(imageSize)
  // return `https://${domain}/cdn-cgi/image/width=${width},height=${height},fit=${fit}/${src}`;
  if (!src) return fallBack === 'shareImage' ? fallbackShareImage : placeholder

  // Remove origin to hide from end user
  const IMG_HOST_URL = 'https://storage.googleapis.com/mw-attachments-credits-1/'
  const trimmedSrc = src.replace(IMG_HOST_URL, '')

  return `https://${domain}/image-resizing/v2/${width}/${height}/${fit}/${trimmedSrc}`
}

const createFortAwesomeStr = (str: string, square?: boolean): string => `fa fa-${str}${square ? '-square' : ''}`

export const getLinkMeta = (link: string): SocialLink => {
  const regex = /https?:\/\//
  const url = !regex.test(link) ? `https://${link}` : link
  switch (true) {
  case link.includes('instagram'):
    return {
      type: SocialType.Instagram,
      url,
      icon: createFortAwesomeStr('instagram', true),
    }
  case link.includes('facebook'):
    return {
      type: SocialType.Facebook,
      url,
      icon: createFortAwesomeStr('facebook', true),
    }
  case link.includes('twitter'):
    return {
      type: SocialType.Twitter,
      url,
      icon: createFortAwesomeStr('twitter', true),
    }
  case link.includes('youtube'):
    return {
      type: SocialType.Youtube,
      url,
      icon: createFortAwesomeStr('youtube', true),
    }
  case link.includes('linkedin'):
    return {
      type: SocialType.LinkedIn,
      url,
      icon: createFortAwesomeStr('linkedin', true),
    }
  case link.includes('soundcloud'):
    return {
      type: SocialType.SoundCloud,
      url,
      icon: createFortAwesomeStr('soundcloud', true),
    }
  case link.includes('bandcamp'):
    return {
      type: SocialType.Bandcamp,
      url,
      icon: createFortAwesomeStr('bandcamp'),
    }
  case link.includes('vimeo'):
    return {
      type: SocialType.Vimeo,
      url,
      icon: createFortAwesomeStr('vimeo', true),
    }
  case link.includes('etsy'):
    return {
      type: SocialType.Etsy,
      url,
      icon: createFortAwesomeStr('etsy', true),
    }
  default:
    return {
      type: SocialType.Website,
      url,
      icon: createFortAwesomeStr('globe-entypo'),
    }
  }
}

export const parsePersonLinks = (links: string[]): SocialLink[] => links.map((link) => getLinkMeta(link)).sort(linkSort)

interface ContributionProps {
  exhibitions: GatsbyTypes.getEAPRelationshipsQuery['exhibitions']
  anchors: GatsbyTypes.getEAPRelationshipsQuery['anchors']
  projects: GatsbyTypes.getEAPRelationshipsQuery['projects']
  content: GatsbyTypes.getEAPRelationshipsQuery['content']
}

export const getContributionsList = ({
  exhibitions,
  anchors,
  projects,
  content,
}: ContributionProps): GatsbyTypes.Maybe<string>[] => {
  const exhibitionsByAnchor = anchors.nodes.map(
    (el) => el?.data?.Anchor_Space?.[0]?.data?.Exhibition_link?.[0]?.data
      ?.Exhibition_Title,
  )
  const exhibitionsByExhibitionRole = exhibitions.nodes.map(
    (el) => el?.data?.Exhibition__non_linked_,
  )
  const exhibitionsByProject = projects.nodes.map(
    (el) => el?.data?.Project?.[0]?.data?.Exhibition__from_Master_IP_,
  )
  const exhibitionsByContent = content.nodes.map(
    (el) => el?.data?.Exhibition__lookup_?.[0],
  )

  const listWithDuplicates: string[] = [
    ...exhibitionsByAnchor,
    ...exhibitionsByExhibitionRole,
    ...exhibitionsByProject,
    ...exhibitionsByContent,
  ]
    // TODO: remove this. It's to account for bad data
    .map((item) => {
      if (item?.indexOf('?') === -1) {
        const split = item?.split(',')
        const cleanedSplit = split?.map((splitItem) => splitItem.trim())
        return cleanedSplit
      }
      return item
    })
    .flat()
    .filter((item) => item) as string[]

  // remove dups & sort
  return [...new Set(listWithDuplicates)]
    .sort(contributionSort)
    .sort(exhibitionSort)
}

export const getContributionsString = (props: ContributionProps): string => {
  const contributionsList = getContributionsList(props)
  return arrayToReadableList(contributionsList)
}

export const pluralize = (
  count: number,
  string: string,
  stringPlural?: string,
): string => (count === 1 ? string : stringPlural || `${string}s`)

export const scrollToTop = (): void => {
  window?.scrollTo({ top: 0, behavior: 'smooth' })
}

export const transformPeople = (
  peepsList: PeopleT,
): TransformedPerson[] | null => {
  if (!peepsList) return null
  const peeps: TransformedPerson[] = peepsList.map((person) => {
    const projectRelationships = person?.data?.Project_Relationships
    const filters = uniq(person.data?.Filters?.split(',')).join(',')
    const projects = projectRelationships?.map((project) => ({
      project: get(project, 'data.Project_Title', null),
      id: project?.id,
    })) || null
    return {
      id: person.id,
      name: person.data?.Display_Name,
      slug: person.data?.Slug,
      filters,
      projects,
    }
  })
  return peeps
}
const converter = new showdown.Converter({ simpleLineBreaks: true })
export const markdownToHtml = (html: string): string => converter.makeHtml(html)

export const cleanHtmlMdString = (str: string): string => str.replace(/\\(.?)/g, (_, n1) => {
  switch (n1) {
  case '\\':
    return '\\'
  case '0':
    return '\u0000'
  case '':
    return ''
  default:
    return n1
  }
})

export const handleIncomingString = (html: string): string => sanitizeHtml(markdownToHtml(cleanHtmlMdString(html)))

export const getPhotoCredit = (fileName: string): string | null => {
  if (!fileName) return null
  // strip out special chars, lowercase string
  const stripped = fileName.replace(/[^\w\s]/gi, '').toLowerCase()
  // find match
  const found = PHOTOGRAPHERS.find((pg) => stripped.indexOf(pg.name) > -1)
  if (!found) return null
  // output hardcoded photographer value
  return found.output
}

export const getSeoMeta = (
  seoData: (Pick<GatsbyTypes.AirtableData, 'Seo_Title' | 'Seo_Description'> &
    { readonly Seo_Share_Image:
        GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.AirtableDataSeo_Share_Image, 'url'>>>> })
    | undefined,
): { seoTitle: string; seoShareImage: {url: string}[]; seoDescription: string } => {
  const seoImageUrls = get(seoData, 'seo_share_image_storage_urls', false)?.split(',')
  const seoTitle = get(seoData, 'Seo_Title', '')
  const seoShareImage = get(seoData, 'Seo_Share_Image', [])
    ?.map((img, idx) => ({ ...img, url: seoImageUrls[idx] }))
  const seoDescription = get(seoData, 'Seo_Description', '')
  return { seoTitle, seoShareImage, seoDescription }
}

export const getDistinctGroups = (
  group: AllTiersT,
  sortOrder: (a?: string, b?: string) => number,
): [AllTiersT, AllTiersT[]] => {
  const groupRoles = [...new Set(group.map((el) => el?.data?.Tier))]
  const distinctGroups: AllTiersT[] = groupRoles
    .sort(sortOrder)
    .map((role) => group.filter((el) => el?.data?.Tier === role))
  const [firstGroup, ...restGroup] = distinctGroups
  return [firstGroup, restGroup]
}

// Add and/or remove a body class
export const updateBodyClass = ({ action, className } :
  { action: 'remove' | 'add', className: string}): void => {
  switch (action) {
  case 'add':
    document.body.classList.add(className)
    break
  case 'remove':
  default:
    document.body.classList.remove(className)
  }
}

const filenameRegex = /[A-Za-z0-9_-]+/

/**
 * Merge google cloud image urls with Airtable image objects, overwriting original url
 * @param {string[] | null} imageUrls - google cloud storage image urls
 * @param {GatsbyAirtableImages[] | null} imageData - image data from Airtable containing old urls
 * @returns {GatsbyAirtableImages[]} - updated image object data
 */
export const mergeImageData = (
  imageUrls: string[] | null,
  imageData: GatsbyAirtableImages[] | null,
): GatsbyAirtableImages[] => (
  imageData?.map((image) => {
    // remove any odd things like ` (1)`
    const cleanedFilename = (image.filename)?.match(filenameRegex)
    // find the image based on the clean filename
    const foundUrl = cleanedFilename?.[0]
      ? imageUrls?.find((url) => url.includes(cleanedFilename[0] as string))
      : null
    // return the augmented object
    return ({
      ...image,
      url: foundUrl || image.url,
    })
  }) || []
)
