import { ImageSizes, SocialType } from './types'

export const PLACEHOLDER_URL = 'http://placekitten.com'
export const MAIN_CONTENT_ID = 'main-content'
export const HEADER_ID = 'header'
export const PURPLE_BOX_PADDING = 32

export const EXHIBITION_SORT_ORDER = [
  'The Real Unreal',
  'Convergence Station',
  'Omega Mart',
  'House of Eternal Return',
]

export const STORY_SORT_ORDER = [
  'Story Video & Audio Team',
  'Production Crew',
  null,
]

export const CONTRIBUTION_SORT_ORDER = [
  'Exhibition Team',
  'Exhibition Contributors',
  null,
]

export const ANCHOR_SORT_ORDER = [
  'Creative Leads',
  'Creative Contributors',
  null,
]

export const EXHIBITION_CONTENT_SORT_ORDER = [
  'Crew',
  'Cast',
  null,
]

export const PHOTOGRAPHERS = [
  { name: 'kennedycottrell', output: 'Kennedy Cottrell' },
  { name: 'monicalloyd', output: 'Monica Lloyd' },
  { name: 'katerussell', output: 'Kate Russell' },
  // duplicated due to typo in airtable
  { name: 'katerussel', output: 'Kate Russell' },
  { name: 'nathanhindman', output: 'Nathan Hindman' },
  { name: 'jefffierberg', output: 'Jeff Fierberg' },
  { name: 'christopherdevargas', output: 'Christopher DeVargas' },
  { name: 'elisetrivers', output: 'Elise Trivers' },
  { name: 'nikkiarae', output: 'Nikkia A. Rae' },
  { name: 'ashleyroberts', output: 'Ashley Roberts' },
  { name: 'jessbernstein', output: 'Jess Bernstein' },
  { name: 'atlasmedia', output: 'Atlas Media' },
  { name: 'craigbabineau', output: 'Craig Babineau' },
  { name: 'laurentvelasquez', output: 'Laurent Velasquez' },
  { name: 'alexandracarmona', output: 'Alexandra Carmona' },
  { name: 'gusortega', output: 'Gus Ortega' },
  { name: 'colewilson', output: 'Cole Wilson' },
  { name: 'caitlinlemoine', output: 'Caitlin Lemoine' },
  { name: 'shaylablatchford', output: 'Shayla Blatchford' },
  { name: 'paultorres', output: 'Paul Torres' },
  { name: 'cesarsantos', output: 'Cesar Santos' },
  { name: 'kaitlynarmendariz', output: 'Kaitlyn Armendariz' },
  { name: 'imanithomas', output: 'Imani Thomas' },
]

export const PROJECT_DRAWER_ID = 'project-drawer'
export const ROLES = ['Meow Wolf Artists', 'Collaborating Artists', 'Project Team & Support']
export const SOCIAL_SORT_ORDER: SocialType[] = [
  SocialType.Website,
  SocialType.Facebook,
  SocialType.Instagram,
  SocialType.Twitter,
  SocialType.Youtube,
]

// on page
export const PROFILE_IMAGE_SIZE = 192

export const IMAGE_SIZES: {
  [key in ImageSizes]: {
    width: number,
    height: number
  }
} = {
  [ImageSizes.thumbnail]: {
    width: 224,
    height: 149,
  },
  [ImageSizes.profile]: {
    width: PROFILE_IMAGE_SIZE,
    height: PROFILE_IMAGE_SIZE,
  },
  [ImageSizes.medium]: {
    width: 420,
    height: 280,
  },
  [ImageSizes.large]: {
    width: 960,
    height: 640,
  },
  [ImageSizes.shareImage]: {
    width: 1200,
    height: 640,
  },
}
