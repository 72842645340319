// Note: As we add additional venues this file must be updated.
// If a new exhibition is added to VENUES
// it will update what is rendered on the
// ExhibitionLanding component and Person page
import hoerLogo from '../static/assets/hoer-logo.svg'
import omegaLogo from '../static/assets/omega-mart.svg'
import convergenceLogo from '../static/assets/convergence-station.svg'
import gvhLogo from '../static/assets/therealunreal-logo.svg'

import hoerLP from '../static/assets/landing-page/hoer.svg'
import convergenceLP from '../static/assets/landing-page/convergence.svg'
import omegaLP from '../static/assets/landing-page/omm.svg'

export enum VENUE_NAMES {
  SAF = 'House of Eternal Return',
  LVS = 'Omega Mart',
  DEN = 'Convergence Station',
  GVH = 'The Real Unreal'
}

export type GatsbyLocation = {
  hash: string
  host: string
  hostname: string
  href: string
  key: string
  origin: string
  pathname: string
  port: string
  search: string
  state?: {
    [x in string] : string
  },
 }

export type VenueGradient = {
  main: string
  mobile: string
  hover: string
}
export interface Venue {
  title: string
  path: string
  gradient: VenueGradient
  city: string
  logo: string
  logoLandingPage: string
}

interface VenueList {
  GVH: Venue
  DEN: Venue
  LVS: Venue
  SAF: Venue
}

// Listed in order of most recent (desired for landing page)
export const VENUES: VenueList = {
  GVH: {
    path: '/the-real-unreal/',
    title: VENUE_NAMES.GVH,
    city: 'Grapevine',
    gradient: {
      main: 'linear-gradient(90deg, #5B4FA1 25%, rgba(81, 65, 154, 0) 82.29%)',
      mobile: 'linear-gradient(90deg, rgba(81, 65, 154, 0.67) 0%, rgba(81, 65, 154, 0) 100%)',
      hover: 'linear-gradient(-90deg, #5B4FA1 0%, #5B4FA1 100%)',
    },
    logo: gvhLogo,
    logoLandingPage: gvhLogo,
  },
  DEN: {
    path: '/convergence-station/',
    title: VENUE_NAMES.DEN,
    city: 'Denver',
    gradient: {
      main: 'linear-gradient(-90deg, #00C7B1 15.1%, rgba(236, 0, 137, 0) 64.57%)',
      mobile: 'linear-gradient(-90deg, rgba(0, 199, 177, 0.63) 0%, rgba(0, 199, 177, 0) 100%)',
      hover: 'linear-gradient(90deg, #00C7B1 0%, #00C7B1 100%)',
    },
    logo: convergenceLogo,
    logoLandingPage: convergenceLP,
  },
  SAF: {
    path: '/house-of-eternal-return/',
    title: VENUE_NAMES.SAF,
    city: 'Santa Fe',
    gradient: {
      main: 'linear-gradient(90deg, #EC0089 15.1%, rgba(236, 0, 137, 0) 64.57%)',
      mobile: 'linear-gradient(90deg, rgba(236, 0, 137, 0.63) 0%, rgba(236, 0, 137, 0) 100%)',
      hover: 'linear-gradient(90deg, #EC0089 0%, #EC0089 100%)',
    },
    logo: hoerLogo,
    logoLandingPage: hoerLP,
  },
  LVS: {
    path: '/omega-mart/',
    title: VENUE_NAMES.LVS,
    city: 'Las Vegas',
    gradient: {
      main: 'linear-gradient(-90deg, #05BCE7 25%, rgba(5, 188, 231, 0) 82.29%)',
      mobile: 'linear-gradient(-90deg, rgba(5, 188, 231, 0.67) 0%, rgba(5, 188, 231, 0) 100%)',
      hover: 'linear-gradient(90deg, #05BCE7 0%, #05BCE7 100%)',
    },
    logo: omegaLogo,
    logoLandingPage: omegaLP,
  },
}

export const VENUE_TITLES = Object.values(VENUES).map((venue) => venue.title)
export const VENUE_CITIES = Object.values(VENUES).map((venue) => venue.city)

export type PeopleT = GatsbyTypes.PeopleQuery['people']['nodes']

type Project = {
  id: string
  project: string
}
export type TransformedPerson = {
  id: string
  name?: string
  slug: string
  filters: string
  projects: Project[]
}

export enum SocialType {
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Youtube = 'Youtube',
  Bandcamp = 'Bandcamp',
  LinkedIn = 'LinkedIn',
  SoundCloud = 'SoundCloud',
  Vimeo = 'Vimeo',
  Etsy = 'Etsy',
  Website = 'Website',
}

export type SocialLink = {
  url: string,
  type: SocialType,
  icon: string,
}

export enum ProfileType {
  Company = 'Company',
  Individual = 'Individual',
  Collaborative = 'Collaboration/Collective'
}

export enum ResultsType {
  People = 'People',
  Project = 'Project'
}

export enum ImageSizes {
  thumbnail,
  profile,
  medium,
  large,
  shareImage,
}

export interface BreadCrumbLink {
  href: string
  title: string
  isAnchor?: boolean
}

export type GatsbyAirtableImages = GatsbyTypes.AirtableDataProject_Image
  | GatsbyTypes.AirtableDataExhibition_Content_Image
  | GatsbyTypes.AirtableDataAnchor_Space_Image
  | GatsbyTypes.AirtableDataExhibition_Image

export type ImageFit = 'contain' | 'crop' | 'cover' | 'scale-down' | 'pad'

export type ContentType = 'exhibition' | 'exhibition_content' | 'anchor' | 'project'

export type ProjectsT = GatsbyTypes.allProjectsAndExhibitionContentQuery['projects']['nodes']
export type ExhibitionsT = GatsbyTypes.getAllExhibitionsQuery['exhibitions']['nodes']
export type AllTiersT = GatsbyTypes.getExhibitionRelationshipsAndAnchorsQuery['allTiers']['nodes'] |
GatsbyTypes.getAnchorRelationshipsByAnchorSlugQuery['allTiers']['nodes'] |
GatsbyTypes.getProjectPageDataQuery['allTiers']['nodes'] |
GatsbyTypes.getExhibitionContentRelationshipsQuery['allTiers']['nodes']

// Note: this was being generated by graphql-typegen but is no longer being created
export type SitePageContext = {
  readonly displayName: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly personSlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly showMWWCLogo: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly bio: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly headshot: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly hoerContributions: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly omegaContributions: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly convergenceContributions: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly truContributions: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly links: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>>>;
  readonly exhibitionTitle: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly slug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibitionDescription: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibitionLocation: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly anchorTitle: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly anchorDescription: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly anchorSlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibitionSlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly projectTitle: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly projectDescription: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly projectSlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibitionTitleRegex: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly anchorSpaceSlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly anchorSpaceTitle: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly anchorSpaceLinks: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<SitePageContextAnchorSpaceLinks>>>;
  readonly exhibitionContentTitle: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibitionContentTitleSlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibitionContentDescription: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly firstAnchor: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly exhibition: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly team: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly profileType: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly projectLinks: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>>>;
};

type SitePageContextAnchorSpaceLinks = {
  readonly title: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
  readonly href: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
};
